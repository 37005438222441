import React from 'react'
import { setLogout } from '../state';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';


import {
    LogoutOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function LogOut() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = () => {
        dispatch(setLogout());
        navigate("/login");
    };
    return (
        <Button onClick={logOut}><LogoutOutlined /> Deconnection</Button>
    )
}

export default LogOut