

import { Button, Card, Col, Descriptions, Divider, Result, Row, Space, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { getLaiterieById } from '../../db';
import { useNavigate } from 'react-router-dom';

function LaiterieView({ codeLaiterie }) {
    const [loading, setLoading] = React.useState(true);
    const [laiterieD, setLaterieD] = React.useState("");

    const usertoken = useSelector((state) => state.token)

    const navigate = useNavigate();


    const navs = [
        {
            title: "Afficher l'états du stock",
            pathname: "stock"
        },
        {
            title: "Afficher les rapports du quota",
            pathname: "stockactuel"
        },
        {
            title: "Afficher les rapports de consomation ",
            pathname: "consomation"
        },
        {
            title: "Afficher l'états du quota",
            pathname: "quota"
        },
        {
            title: "Masqué",
            pathname: ""
        }
    ]
    const loadData = async () => {
        setLoading(true)
        const response = await getLaiterieById(usertoken, codeLaiterie);
        if (response.message === "success") {
            setLaterieD(response.data)
        }
        console.log('1====================================');
        console.log(response);
        console.log('====================================');
        setLoading(false)
    }
    React.useEffect(() => {
        loadData();
    }, [codeLaiterie])
    return (
        <Card bordered={false}>
            {laiterieD ?
                <>
                    <Divider>
                        <h2>Nom : {laiterieD?.infoLaiterie?.nomLaiterie}</h2>
                    </Divider>
                    <Descriptions title="Laiterie details">
                        <Descriptions.Item label="Numero de contract">{laiterieD.codeLaiterie}</Descriptions.Item>
                        <Descriptions.Item label="Nom de laiterie">{laiterieD.infoLaiterie.nomLaiterie}</Descriptions.Item>
                        <Descriptions.Item label="Wilaya ">{laiterieD.infoLaiterie.codeWilaya}</Descriptions.Item>
                        <Descriptions.Item label="Addresse ">{laiterieD.infoLaiterie.address}</Descriptions.Item>
                        <Descriptions.Item label="Num R.C. ">{laiterieD.infoLaiterie.numRegisterDeCommerce}</Descriptions.Item>
                        <Descriptions.Item label="N° Agrement sanitaire">{laiterieD.infoLaiterie.agreement}</Descriptions.Item>
                        <Descriptions.Item label="NIF ">{laiterieD.infoLaiterie.NIF}</Descriptions.Item>
                        <Descriptions.Item label="N°Article ">{laiterieD.infoLaiterie.numArticle}</Descriptions.Item>
                        <Descriptions.Item label="Nom et Prénom Du Gérant ">{laiterieD.infoLaiterie.gerant}</Descriptions.Item>
                        <Descriptions.Item label="Tel ">{laiterieD.infoLaiterie.Tel}</Descriptions.Item>
                        <Descriptions.Item label="Fax ">{laiterieD.infoLaiterie.Fax}</Descriptions.Item>
                        <Descriptions.Item label="Ancien numero de contrat ">{laiterieD.infoLaiterie.ancienNumDeContract}</Descriptions.Item>
                    </Descriptions>

                    {laiterieD.codeLaiterie.geoLocation &&
                        <Descriptions title="Position GPS">
                            <Descriptions.Item label="Latitude">{laiterieD.codeLaiterie.geoLocation.latitude}</Descriptions.Item>
                            <Descriptions.Item label="Longitude">{laiterieD.infoLaiterie.geoLocation.longitude}</Descriptions.Item>
                        </Descriptions>
                    }
                </> :
                <Result
                    status="warning"
                    title="Faild loading!"
                    subTitle="Check if laiterie exist or connection"
                    extra={
                        <Button type="primary" onClick={() => loadData()}>
                            "refresh"
                        </Button>
                    }
                />

            }
        </Card >
    )
}

export default LaiterieView