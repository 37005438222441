
import React from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Radio, Result, Row, Select, Space, Spin, Tag, message } from 'antd';

import { PlusOutlined } from "@ant-design/icons"

import appdata from "../../appData.json"
import { addFournisseur, addLaiterie, addQuota, getFournisseursList, getLaiteriesList, setProductionMensieulle } from '../../db';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../mainlayout';


const AddLaiterieForm = () => {

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const [form] = Form.useForm();

    const usertoken = useSelector((state) => state.token)

    const navigate = useNavigate();

    const onFinish = async (values) => {
        let foo = ['codeLaiterie', 'nomLaiterie',
            'address', 'password', 'codeWilaya',
            'agrem', 'article', 'NIF', 'gerant', 'Tel', 'Fax',
            'latitude', 'longitude', 'PDL0', 'PDL26'];

        const laiterie = {
            codeLaiterie: values.codeLaiterie,
            infoLaiterie: {
                nomLaiterie: values.nomLaiterie,
                codeWilaya: values.codeWilaya,
                address: values.address,
                numRegisterDeCommerce: values.nrc,
                agreement: values.agrem,
                NIF: values.NIF,
                numArticle: values.article,
                Gerant: values.Gerant,
                Tel: values.Tel,
                Fax: values.Fax,
                Geolocation: {
                    longitude: values.longitude,
                    latitude: values.latitude
                }
            },
            LaiterieStock: {
                PDL0: values.PDL0,
                PDL26: values.PDL26
            }
        }
        console.log(Object.keys(values))
        setLoading(true)
        const responseJson = await addLaiterie(usertoken, laiterie);
        setSaved(responseJson.message);
        setLoading(false);
    }

    const onFormLayoutChange = (values) => {

    };
    const onFinishFailed = (errorInfo) => {

    }



    return (

        <MainLayout >
            <Spin spinning={loading}
            >
                {saved === "" ?


                    <div
                        className='centerd-Col'>

                        <Card
                            bordered
                            title={"Laiterie "}
                        >
                            <Form
                                layout={"vertical"}
                                form={form}
                                onFinish={onFinish}
                                onValuesChange={onFormLayoutChange}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Divider orientation='left'>Laiterie details</Divider>
                                        <Form.Item
                                            label="Numero de contract"
                                            name="codeLaiterie"
                                            rules={[{ required: true, message: "Veuillez saisir le Numero de contract!" }]}
                                        >
                                            <Input placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Nom de Laiterie"
                                            name="nomLaiterie"
                                            rules={[{ required: true, message: "Veuillez saisir le Nom de laiterie!" }]}
                                        >
                                            <Input placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="addresse de la laiterie"
                                            name="address"
                                            rules={[{ required: true, message: "Veuillez saisir l'addresse de la laiterie !" }]}
                                        >
                                            <Input
                                                type='address'
                                                placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mot de pass"
                                            name="password"
                                            rules={[{ required: true, message: "Veuillez saisir le mot de pass!" }]}
                                        >
                                            <Input
                                                type='password'
                                                placeholder="-------------" />
                                        </Form.Item>

                                        <Form.Item
                                            label="N° Registre de Commerce"
                                            name="nrc"
                                            rules={[{ required: true, message: "Veuillez saisir le N° Registre de Commerce !" }]}
                                        >
                                            <Input
                                                type='address'
                                                placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Wilaya"
                                            name="codeWilaya"
                                            rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                        >
                                            <Select>
                                                {appdata.wilayat.map((item, index) =>
                                                    <Select.Option value={item.codeWilaya} key={index}>{item.codeWilaya} <Divider type='vertical' />{item.nomWilaya}</Select.Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Row style={{ gap: 10 }}>
                                            <Form.Item
                                                label="N Agrement sanitaire"
                                                name="agrem"
                                                rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                            >
                                                <Input placeholder="-------------" />
                                            </Form.Item>
                                            <Form.Item
                                                label="N°Article"
                                                name="article"
                                                rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                            >
                                                <Input placeholder="-------------" />
                                            </Form.Item>
                                        </Row>
                                        <Form.Item
                                            label="NIF"
                                            name="NIF"
                                            rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                        >
                                            <Input placeholder="-------------" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Nom et Prénom Du Gérant"
                                            name="gerant"
                                            rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                        >
                                            <Input placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="TEL"
                                            name="Tel"
                                            rules={[{ required: true, message: "Veuillez saisir le Tel!" }]}
                                        >
                                            <Input
                                                type='Tel'
                                                placeholder="-------------" />
                                        </Form.Item>
                                        <Form.Item
                                            label="FAX"
                                            name="Fax"
                                            rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                                        >
                                            <Input placeholder="-------------"
                                                type='Fax'
                                            />
                                        </Form.Item>
                                        <Divider orientation='left'>GeoLocation</Divider>
                                        <Row style={{ gap: 10 }}>
                                            <Form.Item
                                                label="Latitude"
                                                name="latitude"
                                            >
                                                <Input placeholder="0"
                                                    type='Number'
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Longitude"
                                                name="longitude"
                                            >
                                                <Input placeholder="0"
                                                    type='Number'
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Divider orientation='left'>Stock actuel</Divider>
                                        <Form.Item
                                            name="PDL0"
                                            label="PDL 0%"
                                        >
                                            <Input placeholder="-------------" suffix={"kg"} />
                                        </Form.Item>
                                        <Form.Item
                                            name="PDL26"
                                            label="PDL 26%">
                                            <Input placeholder="-------------" suffix={"kg"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20, gap: 10 }}>
                                    <Space size={12}>
                                        <Button type="primary" htmlType="submit">Ajouter la laiterie</Button>
                                        <Button onClick={() => {
                                            form.resetFields();
                                        }}>Clear</Button>
                                    </Space>
                                </Row>
                            </Form>
                        </Card>
                    </div> :

                    <Result
                        status={saved === "success" ? "success" : "error"}
                        title={saved === "success" ? "Success" : "Error"}
                        subTitle={saved === "success" ? "Report was saved successfully" : "" + saved}
                        extra={
                            <Space>
                                <Button type="primary" onClick={() => setSaved("")}>
                                    {saved === "success" ? "Ajouter une autres" : "Try again"}
                                </Button>

                                <Button onClick={() => navigate("/laiteries")}>
                                    {"Close"}
                                </Button>
                            </Space>
                        }
                    />
                }
            </Spin >
        </MainLayout>
    );
};

const AddFournisseurForm = () => {

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const [form] = Form.useForm();

    const usertoken = useSelector((state) => state.token)

    const navigate = useNavigate();

    const onFinish = async (values) => {
        console.log(values)
        setLoading(true)
        const responseJson = await addFournisseur(usertoken, values);
        setSaved(responseJson.message);
        setLoading(false);
    }

    const onFormLayoutChange = (values) => {

    };
    const onFinishFailed = (errorInfo) => {

    }



    return (
        <Spin spinning={loading}
        >
            {saved === "" ?


                <div
                    className='centerd-Col'>

                    <Divider orientation='center'>Fournisseur détails</Divider>
                    <Form
                        style={{
                            maxWidth: 600,
                        }}
                        layout={"vertical"}
                        form={form}
                        onFinish={onFinish}
                        onValuesChange={onFormLayoutChange}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Nom "
                            name="nom"
                            rules={[{ required: true, message: "Veuillez saisir le Nom de laiterie!" }]}
                        >
                            <Input placeholder="-------------" />
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20, gap: 10 }}>
                            <Button type="primary" htmlType="submit">Ajouter le fournisseur</Button>
                            <Button onClick={() => {
                                form.resetFields();
                            }}>Clear</Button>
                        </div>
                    </Form></div> :

                <Result
                    status={saved === "success" ? "success" : "error"}
                    title={saved === "success" ? "Success" : "Error"}
                    subTitle={saved === "success" ? "Report was saved successfully" : "" + saved}
                    extra={
                        <Space>
                            <Button type="primary" onClick={() => setSaved("")}>
                                {saved === "success" ? "Ajouter une autres" : "Try again"}
                            </Button>

                            <Button onClick={() => navigate("/")}>
                                {"Close"}
                            </Button>
                        </Space>
                    }
                />
            }
        </Spin >
    );
};
const ProductionMensieulle = () => {

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const [laiteriesList, setLaiteriesList] = React.useState([])
    const [codeWilaya, setWilaya] = React.useState("")
    const [form] = Form.useForm();

    const usertoken = useSelector((state) => state.token)

    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true)
        const responseJson = await setProductionMensieulle(usertoken, values);
        setSaved(responseJson.message);
        setLoading(false);
    }

    const loadLaiteries = async () => {
        const responseJson = await getLaiteriesList(usertoken);
        if (responseJson.message === "success")
            setLaiteriesList(responseJson.data)
    }



    return (

        <Spin spinning={loading}
        >
            {saved === "" ?


                <div
                    className='centerd-Col'>

                    <Divider orientation='center'>Nouveul quantité de production du LPC par moin</Divider>
                    <Form
                        style={{
                            maxWidth: 600,
                        }}
                        layout={"vertical"}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Wilaya"
                            name="codeWilaya"
                        >
                            <Select onChange={(v) => setWilaya(v)}>
                                {appdata.wilayat.map((doc, index) =>
                                    <Select.Option key={index} value={doc.codeWilaya} >{doc.codeWilaya} <Divider type='vertical' />{doc.nomWilaya}</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Laiterie"
                            name="codeLaiterie"
                            rules={[{ required: true, message: "Veuillez saisir le Fournisseur!" }]}
                        >
                            <Select onClick={loadLaiteries}>
                                {laiteriesList.map((doc, index) => {
                                    if (codeWilaya === doc.infoLaiterie.codeWilaya)
                                        return (
                                            <Select.Option key={index} value={doc.codeLaiterie} >{doc.infoLaiterie.nomLaiterie}</Select.Option>
                                        );

                                }
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Quantité"
                            name="productionMensieulle"
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder='0'
                            />

                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20, gap: 10 }}>
                            <Button type="primary" htmlType="submit">Enregistrer </Button>
                            <Button onClick={() => {
                                form.resetFields();
                            }}>Clear</Button>
                        </div>
                    </Form></div> :

                <Result
                    status={saved === "success" ? "success" : "error"}
                    title={saved === "success" ? "Success" : "Erreur"}
                    subTitle={saved === "success" ? "Les modifications ont été enregistrées" : "!!" + saved}
                    extra={
                        <Space>
                            {saved !== "success" &&
                                <Button type="primary" onClick={() => setSaved("")}>
                                    "Essayer à nouveau"
                                </Button>
                            }
                            <Button onClick={() => navigate("/")}>
                                Fermer
                            </Button>
                        </Space>
                    }
                />
            }
        </Spin >
    );
};


const QuotaForm = () => {

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const [form] = Form.useForm();

    const [LOTs, setLOTs] = React.useState([])
    const [inputVisible, setInputVisible] = React.useState(false);

    const [codeWilaya, setWilaya] = React.useState("")
    const [fournisseursList, setFournisseursList] = React.useState([])
    const [laiteriesList, setLaiteriesList] = React.useState([])

    const usertoken = useSelector((state) => state.token);

    const navigate = useNavigate();

    const onFinish = async (values) => {



        setLoading(true)
        console.log('====================================');
        console.log(values);
        console.log('====================================');
        const responseJson = await addQuota(usertoken, {
            codeLaiterie: values.codeLaiterie,
            nomFournisseur: values.nomFournisseur,
            PDL0: values.PDL0,
            PDL26: values.PDL26,
            lot: LOTs,
        });
        setSaved(responseJson.message);
        setLoading(false);
    }

    const onFormLayoutChange = (values) => {

    };
    const onFinishFailed = (errorInfo) => {

    }

    const loadFournisseurList = async () => {
        const responseJson = await getFournisseursList(usertoken);
        if (responseJson.message === "success")
            setFournisseursList(responseJson.data)
    }

    const loadLaiteries = async () => {
        const responseJson = await getLaiteriesList(usertoken);
        if (responseJson.message === "success")
            setLaiteriesList(responseJson.data)
    }



    return (
        <Spin spinning={loading}
        >
            {saved === "" ?
                <div
                    className='centerd-Col'>
                    <Divider orientation='center'>Quota détails</Divider>
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={onFinish}
                        onValuesChange={onFormLayoutChange}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Wilaya"
                            name="codeWilaya"
                        >
                            <Select onChange={(v) => setWilaya(v)}>
                                {appdata.wilayat.map((doc, index) =>
                                    <Select.Option key={index} value={doc.codeWilaya} >{doc.codeWilaya} <Divider type='vertical' />{doc.nomWilaya}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Laiterie"
                            name="codeLaiterie"
                            rules={[{ required: true, message: "Veuillez saisir le Fournisseur!" }]}
                        >
                            <Select onClick={loadLaiteries}>
                                {laiteriesList.map((doc, index) => {
                                    if (codeWilaya === doc.infoLaiterie.codeWilaya)
                                        return (
                                            <Select.Option key={index} value={doc.codeLaiterie} >{doc.infoLaiterie.nomLaiterie}</Select.Option>
                                        );

                                }
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Fournisseur"
                            name="nomFournisseur"
                            rules={[{ required: true, message: "Veuillez saisir le Fournisseur!" }]}
                        >
                            <Select onClick={loadFournisseurList}>
                                {fournisseursList.map((fournisseur, index) =>
                                    <Select.Option key={index} value={fournisseur.nom} >{index + 1}<Divider type='vertical' />{fournisseur.nom}</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Row style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: 20, gap: 10 }}>
                            <Space size={12}>
                                LOT :
                                {LOTs.map((LOT, index) =>
                                    <Tag
                                        key={index}
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            setLOTs(LOTs.filter(perLOT => perLOT !== LOT));
                                        }}
                                    >
                                        {LOT}
                                    </Tag>)}
                                <Form.Item
                                    name="LOT"
                                >
                                    {inputVisible ? (
                                        <Input

                                            type="text"
                                            size="small"
                                            style={{
                                                width: 78,
                                            }}
                                            onPressEnter={() => {
                                                let thisLOT = form.getFieldValue("LOT");

                                                if (thisLOT && thisLOT.length > 0 && LOTs.filter(perLOT => perLOT === form.LOT).length < 1) {
                                                    setLOTs([...LOTs, thisLOT]);
                                                }
                                                form.setFieldValue("LOT", "");
                                                setInputVisible(false);

                                            }}
                                        />
                                    ) : (
                                        <Tag onClick={() => {
                                            setInputVisible(true);

                                        }} >
                                            <PlusOutlined /> Ajouter un LOT
                                        </Tag>
                                    )}
                                </Form.Item>
                            </Space>
                        </Row>
                        <Divider orientation='left'>Quantité</Divider>
                        <Row>
                            <Space size={10}>
                                <Form.Item label="0%" name="PDL0"
                                    rules={[{ required: true, message: "Veuillez saisir la 0%!" }]}>
                                    <Input type="number" placeholder='0' suffix="Kg" />
                                </Form.Item>
                                <Form.Item label="26%" name="PDL26"
                                    rules={[{ required: true, message: "Veuillez saisir la 26%!" }]} >
                                    <Input type="number" placeholder='0' suffix="Kg" />
                                </Form.Item>
                            </Space>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20, gap: 10 }}>
                            <Space size={12}>
                                <Button type="primary" htmlType="submit">Ajouter le quota</Button>
                                <Button onClick={() => {
                                    form.resetFields();
                                }}>Clear</Button>
                            </Space>
                        </Row>
                    </Form>
                </div > :

                <Result
                    status={saved === "success" ? "success" : "error"}
                    title={saved === "success" ? "Success" : "Erreur"}
                    subTitle={saved === "success" ? "Le Rapport vas sauvgarder avec success" : "" + saved}
                    extra={
                        <Space>
                            <Button type="primary" onClick={() => setSaved("")}>
                                {saved === "success" ? "Ajouter une autres" : "Veulliez Réessayer"}
                            </Button>

                            <Button onClick={() => navigate("/laiteries/list")}>
                                {"Close"}
                            </Button>
                        </Space>
                    }
                />
            }
        </Spin >
    );

}


const ViewLaterie = ({ callback }) => {

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const [form] = Form.useForm();

    const [codeWilaya, setWilaya] = React.useState("")
    const [codeLaiterie, setCodeLaiterie] = React.useState("")
    const [laiteriesList, setLaiteriesList] = React.useState([])

    const usertoken = useSelector((state) => state.token);


    const onFinish = async (values) => {
        console.log(values)
        setLoading(true)
        const responseJson = await addQuota(usertoken, values);
        setSaved(responseJson.message);
        setLoading(false);
    }


    const loadLaiteries = async () => {
        const responseJson = await getLaiteriesList(usertoken);
        if (responseJson.message === "success")
            setLaiteriesList(responseJson.data)
    }

    const onFormLayoutChange = (values) => {
        setCodeLaiterie(values.codeLaiterie)
    }
    const viewLaiterie = (v) => {
        setCodeLaiterie(v);
        callback(codeLaiterie);
    }


    return (
        <Form
            style={{ minWidth: "100%" }}
            layout={"inline"}
            form={form}
            onFinish={onFinish}
            onValuesChange={onFormLayoutChange}
            autoComplete="off"
        >
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "baseline", paddingRight: 20, gap: 20, minWidth: "100%" }}>
                <Form.Item
                    style={{ flex: 1 }}
                    label="Wilaya"
                    name="codeWilaya"
                >
                    <Select onChange={(v) => setWilaya(v)}>
                        {appdata.wilayat.map((doc, index) =>
                            <Select.Option key={index} value={doc.codeWilaya} >{doc.codeWilaya} <Divider type='vertical' />{doc.nomWilaya}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="codeLaiterie"
                    style={{ flex: 1 }}
                    label="Laiterie"
                    rules={[{ required: true, message: "Veuillez saisir le Fournisseur!" }]}
                >
                    <Select
                        onClick={loadLaiteries}
                    >
                        {laiteriesList.map((doc, index) => {
                            if (codeWilaya === doc.infoLaiterie.codeWilaya)
                                return (
                                    <Select.Option key={index} value={doc.codeLaiterie} >{doc.infoLaiterie.nomLaiterie}</Select.Option>
                                );

                        }
                        )}
                    </Select>
                </Form.Item>
                <Button type="primary" onClick={viewLaiterie}>
                    Afficher
                </Button>
                <Button onClick={() => {
                    form.resetFields();
                }}>Clear</Button>
            </div>
        </Form>
    );

}
export default { AddLaiterieForm, AddFournisseurForm, QuotaForm, ViewLaterie, ProductionMensieulle };