



const DB_SERVER = process.env.REACT_APP_DB_SERVER 


export const adminlogin = async (values) => {
    try {
        const loggedInResponse = await fetch(DB_SERVER + "admin/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        })
        const loggedIn = await loggedInResponse.json();
console.log(loggedIn);
        return loggedIn
    } catch (err) {
        return "Error ! check your internet."
    }
};

export const addLaiterie = async (usertoken, values) => {
    try {

        const response = await fetch(DB_SERVER + "admin/laiteries/addlaiterie", {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                ...values,
            }),
        })
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (err) {
        console.log('====================================');
        console.log(err);
        console.log('====================================');
        return { message: err }
    }
};
export const addFournisseur = async (usertoken, values) => {
    try {

        const response = await fetch(DB_SERVER + "admin/fournisseurs/addfournisseur", {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                ...values,
            }),
        });
        const jsonResponse = await response.json();
        console.log('====================================');
        console.log(jsonResponse);
        console.log('====================================');
        return jsonResponse;
    } catch (err) {
        console.log(err)
        return { message: err }
    }
};
export const addQuota = async (usertoken, values) => {
    try {

        const response = await fetch(DB_SERVER + `admin/laiterie/addquota`, {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                ...values,
            }),
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (err) {
        console.log(err)
        return { message: err }
    }
};
export const setProductionMensieulle = async (usertoken, values) => {
    try {

        const response = await fetch(DB_SERVER + `admin/laiterie/setMonthlyProduction`, {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                ...values,
            }),
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (err) {
        console.log(err)
        return { message: err }
    }
};


export const getLaiterieById = async (usertoken, codeLaiterie) => {
    try {

        const response = await fetch(DB_SERVER + `admin/laiterie/info`, {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                codeLaiterie,
            }),
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (err) {
        console.log(err)
        return { message: err }
    }
}

export const getLaiteriesList = async (usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "admin/laiteries/list", {
            method: "GET",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" }
        });

        const jsonResponse = await response.json();

        console.log('====================================');
        console.log(jsonResponse);
        console.log('====================================');
        if (jsonResponse.message !== "success")
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;

        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};
export const getFournisseursList = async (usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "admin/fournisseurs/list", {
            method: "GET",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" }
        });

        const jsonResponse = await response.json();

        console.log('====================================');
        console.log(jsonResponse);
        console.log('====================================');
        if (jsonResponse.message !== "success")
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;

        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getConsomationReportsList = async (codeLaiterie, usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "admin/laiterie/consomationreports", {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                codeLaiterie
            }),
        });

        const jsonResponse = await response.json();

        if (jsonResponse.message === "success") {
            console.log('====================================');
            console.log("success ! got ONIL Inventaire reports ,Total:", jsonResponse.data.length);
            console.log('====================================');
        } else {
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;
        }
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getStockReportsList = async (codeLaiterie, usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "admin/laiterie/stockactuelreports", {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                codeLaiterie
            }),
        });

        const jsonResponse = await response.json();

        if (jsonResponse.message === "success") {
            console.log('====================================');
            console.log("success ! got ONIL Inventaire reports ,Total:", jsonResponse.data.length);
            console.log('====================================');
        } else {
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;
        }
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getQuotasList = async (codeLaiterie, usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "admin/laiterie/quotas", {
            method: "POST",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                codeLaiterie
            }),
        });

        const jsonResponse = await response.json();

        if (jsonResponse.message === "success") {
            console.log('====================================');
            console.log("success ! got quotas ,Total:", jsonResponse.data.length);
            console.log('====================================');
        } else {
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;
        }
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getInventaireReportById = async (_id, usertoken, rpId) => {
    try {
        const response = await fetch(DB_SERVER + "ONIL/inventairereports/" + _id + "/inventaire/" + rpId, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            }
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message === "success") {
            console.log('====================================');
            console.log("success ! got ONIL Inventaire reports ,Total:", jsonResponse.data.length);
            console.log('====================================');
        } else {
            console.log('====================================');
            console.log("failed ! didn't got ONIL Inventaire reports :", jsonResponse.message);
            console.log('====================================');
        }
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getLaiterieInventaire = async (codeLaiterie, usertoken) => {
    try {
        const response = await fetch(DB_SERVER + "admin/laiterie/stock", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            },
            body: JSON.stringify({ codeLaiterie })
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message !== "success")
            throw jsonResponse;

        return jsonResponse;
    } catch (err) {
        return err
    }
}
