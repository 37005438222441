import React from 'react';
import {
    Layout,
    Menu,
    Button,
    Typography,
    Card,
    Form,
    Input,
    Checkbox,
    Divider,
    Space,
    Image,
    Row,
    Statistic,
    Col,
    Tabs,
} from "antd";


import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";

import {
    DribbbleOutlined,
    TwitterOutlined,
    InstagramOutlined,
    GithubOutlined,
} from "@ant-design/icons";

import onil from "../../assets/images/onil.png"
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../components/mainlayout';
import { StockGadjet } from '../../components/uigadjets';
import Forms from '../../components/forms';
import LaiterieView from '../../components/laiterieview';
import ConsomationReports from '../consomationreports';
import StockActuelReports from '../stockactuelreports';
import { QuotaReports } from '../QuotaReports';



const { Header, Footer, Content, Sider } = Layout;

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '180px',
    color: '#fff',
    backgroundColor: '#fff0',
    padding: 10,
    height: "100vh",
    width: 280,
    position: "fixed",
};
function DashBoard() {

    const [laiterie, setLaiterie] = React.useState("");



    return (
        <MainLayout >
            <Row gutter={24} style={{ minWidth: "100%" }}>
                <Divider orientation='left'>Laiterie</Divider>
            </Row>
            <Forms.ViewLaterie callback={setLaiterie} />

            {laiterie &&
                <Tabs defaultActiveKey="1" items={[{
                    key: '0',
                    label: `Laiterie information`,
                    children: <LaiterieView codeLaiterie={laiterie} />,
                }, {
                    key: '1',
                    label: `Laiterie Stock`,
                    children: <StockGadjet codeLaiterie={laiterie} />,
                },
                {
                    key: '2',
                    label: `Laiterie consommation`,
                    children: <ConsomationReports codeLaiterie={laiterie} />,
                },
                {
                    key: '3',
                    label: `Laiterie Stock Rapports `,
                    children: <StockActuelReports codeLaiterie={laiterie} />,
                },
                {
                    key: '4',
                    label: `Laiterie quota `,
                    children: <QuotaReports codeLaiterie={laiterie} />,
                }

                ]} />

            }



        </MainLayout >
    );
}

export default DashBoard