import React from 'react'
import MainLayout from '../../components/mainlayout'
import { Button, Card, Col, Divider, List, Result, Row, Space, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SyncOutlined, FileAddOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { getLaiteriesList } from '../../db';

import appdata from "../../appData.json"
import Forms from '../../components/forms';
import FournisseursList from '../../components/FournisseursList';

const dataHeader = [{ title: "Numero de Contract", index: "codeLaiterie", key: 0 },
{ title: "Nom Laiterie", index: "nomLaiterie", key: 1 },
{ title: "Wilaya Number", index: "codeWilaya", key: 2 },
{ title: "Nom Wilaya", index: "nomWilaya", key: 3 }];

function Manager() {

    const { Column } = Table;

    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const usertoken = useSelector((state) => state.token);



    return (
        <MainLayout >
            <Row gutter={22}>
                <Col span={10}>
                    <Forms.ProductionMensieulle />
                    <Forms.AddFournisseurForm />
                    <FournisseursList />
                </Col>
                <Col span={2}>
                    <Divider type='vertical' style={{ height: "100%" }} />
                </Col>
                <Col span={10}>
                    <Forms.QuotaForm /></Col>
            </Row>
        </MainLayout>
    )
}

export default Manager