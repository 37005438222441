import { Button, Result } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../components/mainlayout';

function NotFound(props: any) {
    const navigate = useNavigate();

    const redirectTo = () => navigate(props?.to || "/");
    return (
        <MainLayout  >
            <Result
                status="warning"
                title="the requested link was not found"
                extra={
                    <Button type="primary" key="goHome" onClick={redirectTo}>
                        Go Home
                    </Button>
                }
            />

        </MainLayout>
    )
}
function CommingSoon(props: any) {
    const navigate = useNavigate();

    const redirectTo = () => navigate(props?.to || "/");
    return (
        <MainLayout  >
            <Result
                status="info"
                title="Comming Soon !"
                subTitle="This section is still under developement"
                extra={
                    <Button type="primary" key="goHome" onClick={redirectTo}>
                        Go Home
                    </Button>
                }
            />

        </MainLayout>
    )
}

export { NotFound, CommingSoon }