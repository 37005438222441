import React from 'react'
import MainLayout from '../../components/mainlayout'
import { Button, Card, Col, Divider, List, Result, Row, Space, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SyncOutlined, FileAddOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { getStockReportsList } from '../../db';


const dataHeader = [{
    title: "Stock initial", index: "Stock_initial",
    sorter: {
        compare: (a, b) => a.Stock_initial - b.Stock_initial,
        multiple: 3,
    },
},
{ title: "Entreé", index: "Entree" },
{ title: "Stock final", index: "Stock_final" }]

function StockActuelReports({ codeLaiterie }) {

    const { Column, ColumnGroup } = Table;

    const [IdList, setIdList] = React.useState([]);
    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();


    const usertoken = useSelector((state) => state.token);

    const load = async () => {
        setLoading(true);
        try {
            const answer = await getStockReportsList(codeLaiterie, usertoken);

            if (answer.message === "success") {
                let newData = [];
                answer.data.forEach((item, index) => {
                    newData.push({
                        key: item._id,
                        reportId: item.reportId,
                        reportDate: new Date(item.reportDate).toLocaleDateString(),
                        "Stock_initial-PDL0": item.laitData.Stock_initial["PDL0"],
                        "Stock_initial-PDL26": item.laitData.Stock_initial["PDL26"],
                        "Entree-PDL0": item.laitData.Entree["PDL0"],
                        "Entree-PDL26": item.laitData.Entree["PDL26"],
                        "Stock_final-PDL0": item.laitData.Stock_final["PDL0"],
                        "Stock_final-PDL26": item.laitData.Stock_final["PDL26"],
                        fournisseur: item.fournisseur,
                        lot: item.lot,
                    })
                })
                setDataSource(newData);
                setLoading(false);
            } else {
                throw "Faild getting reports list :" + answer.message;
            }
        } catch (err) {

            console.log('====================================');
            console.log("Error ! check your internet");
            console.log('====================================');
            setLoading(false);
        }
    }

    React.useEffect(() => { load(); }, []);

    return (

        <Card title={"Stock Actuel Rapports"}
            bordered={false}>
            <Spin spinning={loading} >
                {dataSource.length < 1 ?
                    <Result
                        title="Pas de rapports"
                    />
                    :
                    <div>
                        <Table
                            bordered={true}

                            pagination={{
                                pageSize: 50,
                            }}
                            scroll={{
                                y: 500,
                            }}
                            dataSource={dataSource}>
                            <Column title="Date" dataIndex="reportDate" key="date" />
                            {dataHeader.map((coll, index) => {
                                return (
                                    <ColumnGroup title={coll.title} key={index}>
                                        <Column title="0%" dataIndex={coll.index + "-PDL0"} key={index + coll.index + "-PDL0"} />
                                        <Column title="26%" dataIndex={coll.index + "-PDL26"} key={index + coll.index + "-PDL26"} />
                                    </ColumnGroup>);
                            })}
                            <ColumnGroup title="Quota" key={"quota"}>
                                <Column title="LOT" dataIndex="lot" key="lot" />
                                <Column title="Fournisseur" dataIndex="fournisseur" key="fournisseur" />
                            </ColumnGroup>
                        </Table>

                    </div>
                }
            </Spin>
        </Card>
    )
}

export default StockActuelReports