import React from 'react'
import MainLayout from '../../components/mainlayout'
import { Button, Card, Col, Divider, List, Result, Row, Space, Spin, Table } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { SyncOutlined, FileAddOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { getConsomationReportsList, getConsomationReportsReportsList } from '../../db';


const dataHeader = [{ title: "Stock initial", index: "Stock_initial" },
{ title: "Consommations", index: "Consommations" },
{ title: "Stock final", index: "Stock_final" }]

function ConsomationReports({ codeLaiterie }) {

    const { Column, ColumnGroup } = Table;

    const [IdList, setIdList] = React.useState([]);
    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();


    const user = useSelector((state) => state.user);
    const usertoken = useSelector((state) => state.token);

    const load = async () => {
        if (!user)
            console.log("Auth required")
        setLoading(true);
        try {
            const answer = await getConsomationReportsList(codeLaiterie, usertoken);

            if (answer.message === "success") {
                let newData = [];
                console.log('====================================');
                console.log("success, total reports :", answer.data.length);
                console.log("success, total reports :", answer.data);
                console.log('====================================');
                answer.data.forEach((item, index) => {
                    newData.push({
                        key: item._id,
                        reportId: item.reportId,
                        reportDate: new Date(item.reportDate).toLocaleDateString(),
                        "Stock_initial-PDL0": item.laitData.Stock_initial["PDL0"],
                        "Stock_initial-PDL26": item.laitData.Stock_initial["PDL26"],
                        "Consommations-PDL0": item.laitData.Consommations["PDL0"],
                        "Consommations-PDL26": item.laitData.Consommations["PDL26"],
                        "Stock_final-PDL0": item.laitData.Stock_final["PDL0"],
                        "Stock_final-PDL26": item.laitData.Stock_final["PDL26"],
                        "Production_LPC": item.laitData.Production_LPC
                    })
                })
                setDataSource(newData);
                setLoading(false);
            } else {
                throw "Faild getting reports list :" + answer.message;
            }
        } catch (err) {

            console.log('====================================');
            console.log("error :", err);
            console.log('====================================');
            setLoading(false);
        }
    }

    React.useEffect(() => { load(); }, []);

    return (
        <Card
            bordered={false}
            title={"Stock Actuel"}>
            <Spin spinning={loading} >
                {dataSource.length < 1 ?
                    <Result
                        title="Pas de rapports"
                    />
                    :
                    <div>
                        <Table
                            bordered={true}

                            pagination={{
                                pageSize: 50,
                            }}
                            scroll={{
                                y: 500,
                            }}
                            dataSource={dataSource}>
                            <Column title="Date" dataIndex="reportDate" key="date" />
                            {dataHeader.map((coll, index) => {
                                return (
                                    <ColumnGroup title={coll.title} key={index}>
                                        <Column title="0%" dataIndex={coll.index + "-PDL0"} key={index + coll.index + "-PDL0"} />
                                        <Column title="26%" dataIndex={coll.index + "-PDL26"} key={index + coll.index + "-PDL26"} />
                                    </ColumnGroup>);
                            })}
                            <Column title="Production LPC" dataIndex="Production_LPC" key="Production_LPC" />
                        </Table>

                    </div>
                }
            </Spin>
        </Card>
    )
}

export default ConsomationReports