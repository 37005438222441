import React from 'react';
import { Card, Divider, Space, Statistic } from 'antd';
import { useSelector } from 'react-redux';
import { getLaiterieInventaire } from '../../db';


const StockGadjet = ({ codeLaiterie }) => {

    const [PDL0, setPDL0] = React.useState(0);
    const [PDL26, setPDL26] = React.useState(0);
    const [productionMensieulle, setproductionMensieulle] = React.useState(0);
    const unitConv = (value) => {
        if (value > 1000) {
            return { value: value / 1000, unite: "ton" }
        } else {
            return { value: value, unite: "kg" }
        }
    }

    const usertoken = useSelector((state) => state.token);

    const load = async () => {
        try {
            const answer = await getLaiterieInventaire(codeLaiterie, usertoken);

            if (answer.message === "success") {
                setPDL0(parseFloat(answer.data.PDL0));
                setPDL26(parseFloat(answer.data.PDL26));
                setproductionMensieulle(answer.data.productionMensieulle)
            } else {
                throw "Faild getting reports list :" + answer.message;
            }
        } catch (err) {

            console.log('====================================');
            console.log("error :", err);
            console.log('====================================');
        }
    }

    React.useEffect(() => { load(); }, []);
    return (
        <div >
            <Divider orientation="left">Stock Actuel </Divider>
            <Space size={12}>
                <Card bordered={false}>
                    <Statistic
                        title="PDL0"
                        value={PDL0 ? unitConv(PDL0).value : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={unitConv(PDL0).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="PDL26"
                        value={PDL26 ? unitConv(PDL26).value : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={unitConv(PDL26).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="Quantité de production convention de LPC par mois"
                        value={productionMensieulle || 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={"litres"}
                    />
                </Card>
            </Space>
        </div>
    );
}

export { StockGadjet, };