import React from 'react';
import {
    Button,
    Form,
    Grid,
    Input,
    Select,
    Space,
    Steps,
    theme, Col, Row, Divider, Spin, Typography, Result
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import appdata from "../../appData.json"
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../state";


import { useNavigate, useParams } from 'react-router-dom';
import { adminlogin } from '../../db';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 14, span: 16 },
};



const steps = [
    {
        title: 'Choisir la Wilaya ',
        content: 'First-content',
    },
    {
        title: 'Choisir la Laiterie',
        content: 'Second-content',
    },
    {
        title: 'Authentification',
        content: 'Last-content',
    },
];

const LoginForm = () => {
    const { token } = theme.useToken();

    const usertoken = useSelector((state) => state.token)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const [appData, setAppData] = React.useState({ laiteries: [] });

    React.useEffect(() => {
        if (usertoken) {
            navigate("/")
        } else {
            setLoading(false);
        }
    }, [])


    const onFinish = (values) => {

        console.log('====================================');
        console.log(values);
        console.log('====================================');
        login(values);
    };


    const login = async (values) => {

        setLoading(true)
        try {
            const loggedIn = await adminlogin(values)

            if (loggedIn.message === "success") {
                console.log('====================================');
                console.log(loggedIn);
                console.log('====================================');
                dispatch(
                    setLogin({
                        adminUser: loggedIn?.data.admin,
                        token: loggedIn?.data.token,
                    })
                );
                navigate("/");
            } else {
                throw loggedIn.message
            }
            setLoading(false);
        } catch (err) {
            setMessage("Error Connection :")
            console.log(err)
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Spin spinning={loading}>

            <Row style={{ padding: 24 }}>
                <Typography.Title>
                    Login  Adminstrator
                </Typography.Title>
            </Row>
            {message !== "" ?

                <Result
                    status={message === "success" ? "success" : "error"}
                    title={message === "success" ? "Success" : "Error"}
                    subTitle={message === "success" ? "Logged in" : message}
                    extra={
                        <Button type="primary" onClick={() => setMessage("")}>
                            {message === "success" ? "Go to dashboard" : "Try again"}
                        </Button>
                    }
                />
                :
                <Form
                    name="basic"
                    {...layout}
                    style={{ maxWidth: 600, margin: 20 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Utilisateur"
                        name="username"
                        rules={[{ required: true, message: "Veuillez saisir votre nom d'utilisateur !" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mot de passe :"
                        name="password"
                        rules={[{ required: true, message: "Veuillez saisir votre mot de passe !s" }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Mot de passe"
                        />
                    </Form.Item>
                    <Form.Item {...tailLayout}  >
                        <Button type="primary" htmlType="submit">
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            }
        </Spin>
    );
};





export default LoginForm;