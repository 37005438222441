import React from 'react';
import {
    Layout,
    Menu,
    Button,
    Typography,
    Card,
    Form,
    Input,
    Checkbox,
    Divider,
    Space,
    Image
} from "antd";


import { Link } from "react-router-dom";

import {
    DribbbleOutlined,
    TwitterOutlined,
    InstagramOutlined,
    GithubOutlined,
} from "@ant-design/icons";

import onil from "../../assets/images/onil.png"
import LoginForm from "./form"
import Title from 'antd/es/typography/Title';

import logo from "../../assets/images/logo.jpeg"


const { Header, Footer, Content } = Layout;
const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    padding: 50,
    lineHeight: '64px',
    backgroundColor: '#fafafa',
};

const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#fff',
};
/*
const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#3ba0e9',
};*/

const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',
};
function Login() {
    return (
        <>

            <div className="layout-default">
                <Content className="p-0">
                    <div className="sign-up-header">
                    </div>
                    <Card
                        className="card-signup header-solid h-full ant-card pt-0"
                        style={{ boxShadow: "0px 0px 10px 0px rgb(199,199,199 , 0.46)" }}
                        bordered={false}
                    >
                        <div className='centerd-Col'>
                            <Image width={80} src={logo} style={{ borderRadius: 10 }} />
                            <h5>Office National Interprofessionnel du Lait et des produits laitiers</h5>
                            <h5>الديوان الوطني المهني للحليب</h5>
                        </div>
                        <LoginForm />
                    </Card>

                </Content>
                <Footer >
                    <p className="copyright">
                        {" "}
                        Copyright © 2023  by  <a href="#ONIL">Office National Interprofessionnel du Lait et des produits laitiers</a>.{" "}
                    </p>
                </Footer>
            </div></>
    )
}

export default Login