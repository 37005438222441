import React from 'react'
import { getFournisseursList } from '../../db';
import { Divider, List, Row } from 'antd';
import { useSelector } from 'react-redux';

function FournisseursList() {

    const [fournisseursList, setFournisseursList] = React.useState([])


    const usertoken = useSelector((state) => state.token);

    const loadFournisseurList = async () => {
        const responseJson = await getFournisseursList(usertoken);
        if (responseJson.message === "success")
            setFournisseursList(responseJson.data)
    }

    React.useEffect(() => { loadFournisseurList(usertoken) }, [])
    return (

        fournisseursList && fournisseursList.map((fitem, index) => <Row><h5> {index + 1}<Divider type='vertical' />{fitem.nom}</h5></Row>

        )
    )
}

export default FournisseursList