import React, { useState } from 'react';
import Login from './pages/login';


import {
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import DashBoard from './pages/dashboard';


import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/costum.css"
import { useDispatch, useSelector } from 'react-redux';
import { NotFound, CommingSoon } from './pages/utils';
import LaiteriesList from './pages/laiterieslist';
import Manager from './pages/manager';
import ConsomationReports from './pages/consomationreports';
import Forms from './components/forms';



function App() {
  const usertoken = useSelector((state) => state.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const loadAppData = async () => {
    if (!usertoken)
      return

  }

  function requireAuth(nextState, replace) {
    if (!usertoken && window.location.pathname !== '/login') {
      window.location.pathname = '/login';
    }
  }
  React.useEffect(() => { requireAuth(); }, [])

  return (
    <div className="App">
      <Routes >
        <Route path="/" >
          <Route path='/' element={<DashBoard />} />
          <Route path="laiteries" element={<LaiteriesList />} />
          <Route path="addlaiterie" element={<Forms.AddLaiterieForm />} />
          <Route path="managment" element={<Manager />} />
          <Route path="statistics" element={<CommingSoon to="/" />} />
          <Route path='*' element={<NotFound to="/" />} />
        </Route>
        <Route path="/laiterie">
          <Route path="laiterie/consomationreports" element={<ConsomationReports />} />
          <Route path="laiterie/stockactuelreports" element={<ConsomationReports />} />
        </Route>
        <Route path="/info" element={<CommingSoon to="/" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
