import React from 'react'
import MainLayout from '../../components/mainlayout'
import { Button, Card, Col, Divider, List, Result, Row, Space, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SyncOutlined, FileAddOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { getLaiteriesList } from '../../db';

import appdata from "../../appData.json"

const dataHeader = [{ title: "Numero de Contract", index: "codeLaiterie", key: 0 },
{ title: "Nom Laiterie", index: "nomLaiterie", key: 1 },
{ title: "Wilaya Number", index: "codeWilaya", key: 2 },
{ title: "Nom Wilaya", index: "nomWilaya", key: 3 },

];

function LaiteriesList() {

    const { Column } = Table;

    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const usertoken = useSelector((state) => state.token);

    const load = async () => {
        setLoading(true);
        try {
            const answer = await getLaiteriesList(usertoken);
            if (answer.message === "success") {
                let newData = [];

                answer.data.forEach((item, index) => {
                    const wilayaname = appdata.wilayat.find((i) => i.codeWilaya === item?.infoLaiterie?.codeWilaya)
                    newData.push({
                        key: newData.length,
                        codeLaiterie: item.codeLaiterie,
                        codeWilaya: item?.infoLaiterie?.codeWilaya,
                        nomLaiterie: item?.infoLaiterie?.nomLaiterie,
                        nomWilaya: wilayaname?.nomWilaya || "Uknown willaya",
                        viewStockReport: () => navigate("/laiterie/" + item.codeLaiterie + "/stockactuelreports"),
                        viewConsoReport: () => navigate("/laiterie/" + item.codeLaiterie + "/consomationreports"),
                    });
                });

                setDataSource(newData);
                setLoading(false);
            } else {
                throw "Faild getting reports list :" + answer.message;
            }
        } catch (err) {

            console.log('====================================');
            console.log("Error ! check your internet");
            console.log('====================================');
            setLoading(false);
        }
    }

    React.useEffect(() => { load(); }, []);

    return (
        <MainLayout >
            <Card title={"Stock Actuel"}
                extra={
                    <Space key={"refresh1"}>
                        <Button type="primary" key={"refresh1"} onClick={load} >
                            <SyncOutlined />
                            Refresh
                        </Button>
                        <Button
                            key={"addreports1"}
                            onClick={() => navigate("/addlaiterie")}
                            type="primary"  >
                            <FileAddOutlined />
                            Ajouter une Laiterie
                        </Button>
                    </Space>

                }>
                <Spin spinning={loading} >
                    {dataSource.length < 1 ?
                        <Result
                            title="No reports here"
                            extra={<Space size={10}>
                                <Button
                                    key={"extra1"}
                                    type="primary" onClick={load}>
                                    <SyncOutlined />
                                    Refresh
                                </Button>
                                <Button
                                    key={"extra2"}
                                    onClick={() => navigate("/addinventairereport")}
                                    type="primary" >
                                    <FileAddOutlined />
                                    Ajouter une raport
                                </Button>
                            </Space >
                            }
                        />
                        :
                        <div>
                            <Table
                                bordered={true}
                                pagination={{
                                    pageSize: 50,
                                }}
                                scroll={{
                                    y: 500,
                                }}
                                dataSource={dataSource} >
                                {dataHeader.map((head, index) => <Column title={head.title} dataIndex={head.index} key={index} />)}
                            </Table>

                        </div>
                    }
                </Spin>
            </Card>
        </MainLayout>
    )
}

export default LaiteriesList